<template>
  <div class="filter-tab py-sm-5 py-md-0 serach_tabarea" :class="{ 'search-result': isSearchPage }" v-if="type!=='side'">
    <div class="container">
      <div class="card pt-4 px-4 pb-2 tab-content serach_tabbody">
        <SearchTab v-if="hotelList.data && destinationList.length > 0 && hotelList.data.length > 0" :breadcrumb="`breadcrumb`" :type="device === 'mobile' ? 'mobile' : 'tabs'" @closeSearchMobile="closeSearchMobile" />
        <ContentLoading v-else type="search-tab"/>
        <div v-if="!hotelList.data || destinationList.length === 0 || hotelList.data.length === 0" class="loading-message">{{ $t('search-tab.loading-message') }}</div>
      </div>
    </div>
  </div>
  <SearchTab v-else-if="hotelList.data && destinationList.length > 0 && hotelList.data.length > 0 && type==='side'" :breadcrumb="`breadcrumb`" :type="'side'" @closeSearchMobile="closeSearchMobile" />
</template>

<script>
import TemplateSearchTabs from './TemplateSearchTabs';

export default {
  name: 'SearchTabsTheme1',
  mixins: [TemplateSearchTabs],
  components: {
    SearchTab: () => import('../searchTab/SearchTabTheme1'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
};
</script>

<style scoped>
  @media (max-width: 479px){
    .theme1 .serach_tabbody {
      padding: 0px !important;
    }
    .theme1 .serach_tabarea{
      margin-top: 20px !important;
    }
  }
</style>

<style>
.filter-tab {
  margin-top: -140px;
  position: relative !important;
  z-index: 10;
}
.filter-tab.search-result {
  margin-top: -180px;
}
.banner-area .filter-tab.center-position {
  top: 50%;
}
.mobile_form {
  display: none;
}
/* @media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
} */
.filter-tab form .input-group-append .input-group-text,
.sportspage_area form .input-group-append .input-group-text,
.boxSideSearch .input-group-append .input-group-text {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.filter-tab .input-group > .form-control:not(:last-child),
.sportspage_area .input-group > .form-control:not(:last-child),
.boxSideSearch .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}
.filter-tab .nav-tabs .nav-link.active,
.filter-tab .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: rgba(var(--theme-primary));
  border-color: #dee2e6 #dee2e6 #fff;
}
.filter-tab .nav-tabs .nav-link.active img {
  filter: invert(1);
}
.filter-tab .nav-fill > .nav-link,
.filter-tab .nav-fill .nav-item {
  background-color: rgba(255, 255, 255, 0.7);
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.filter-tab .nav-fill .nav-item:not(:first-child) {
  border-right: 1px solid #fff;
}
.filter-tab .nav-tabs .nav-link {
  color: black;
}
.filter-tab .tab-content {
  background-color: #fff;
}
.filter-tab .tabs .card-header {
  padding: 0.7rem 0.6rem;
}
.filter-tab .tabs .card-header ul.nav.nav-tabs.card-header-tabs.nav-fill {
  padding: 0;
}

.serach_tabarea .card.tab-content {
  border-radius: 1.25rem;
}

.tab-content {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.filter-tab .nav-tabs .nav-link {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.filter-tab .desktop_form .form-group,
.filter-tab .desktop_form .form-group>div,
.filter-tab .desktop_form .st-container .vs--searchable .vs__dropdown-toggle{
  height: 50px;
}
@media (max-width: 767px) and (min-width: 480px) {
  .filter-tab .nav-fill > .nav-link,
  .filter-tab .nav-fill .nav-item {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .filter-tab {
    margin-top: 0px;
  }
  .filter-tab .input-group {
    overflow: unset;
  }
  .filter-tab .tabs .card-header ul.nav.nav-tabs.card-header-tabs.nav-fill {
    flex-wrap: nowrap;
  }
  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    flex: 1 1 0px;
  }
  .filter-tab .nav-tabs .nav-link {
    display: flex;
    flex-wrap: wrap;
    line-height: 14px;
  }
  .filter-tab .filter-Wrap1 .card-header img {
    margin: auto;
  }

  .search-result.filter-tab {
    display: none;
  }
  .loading-message{
    font-size: 1.3rem;
  }
}
</style>
